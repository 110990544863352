import React from 'react'
import Breadcrumbs from '../components/breadcrumbs'
import Helmet from 'react-helmet'
import Hero from '../components/hero'
import EmergenzeTab from '../components/emergenze-tab'
import DoveSiamo from '../components/dove-siamo'
import DocumentiUtili from '../components/documenti-utili'
import PhotoGallery from '../components/photogallery'

import Layout from "../components/layout"
import { graphql, Link } from "gatsby"



class Emergenza extends React.Component {

  render() { 
    const breadcrumb = [{"title":"Emergenza"}];
    const data = this.props.data.contentfulContenutoGenerico;
    const hero = this.props.data.hero;
    const staff = data.staff || []; 
    const datahero = {
      "title": "Ospedale Veterinario Universitario",
      "subtitle": "EMERGENZE 24H",
      "img": hero.childImageSharp.fluid,
      "arealink": false 
    };

    return (
      <Layout location={this.props.location}>
        <Helmet
           title="Emergenza"
           bodyAttributes={
            { class :'has-hero'}
          }
        />
        <Hero data={datahero}/>
        <EmergenzeTab  data="none"/>
        <div className="container">
          <Breadcrumbs breadcrumb={breadcrumb}/>
          <div className="row">
            <div className="col-md-8">
              <h1>Emergenza</h1>
              {!!data && 
                <div className="bodytext" dangerouslySetInnerHTML={{ __html: data.abstract.childMarkdownRemark.html }} >
                
                </div>
              }
              {staff.length>0 &&
                <div>
                  <h2>Staff</h2>
                  <div className="row">
                    {staff.map((item,index) => {
                      let thumb = "/images/staff-placeholder.png";
                      if (item.immagine) {
                        thumb = item.immagineLista.file.url  
                      }
                      return (
                        <div key={index} className="col-sm-6 col-lg-3">
                          <div className="staff-list staff-list--bottom">
                            <Link to={'/staff/'+item.friendlyUrl} title={"Vai alla scheda di " + item.cognome} className="staff-list__name"><img src={thumb} alt={`${item.cognome} ${item.nome}`} className="staff-list__img" /></Link>
                            <div className="staff-list__detail">
                              <Link to={'/staff/'+item.friendlyUrl} title={"Vai alla scheda di " + item.cognome} className="staff-list__name">{item.nome} {item.cognome}</Link>
                              <div className="staff-list__role">{item.ruolo}</div>
                            </div>
                          </div>
                        </div> 
                      )
                      })
                    }
                  </div>
                </div>
              }
              {!!data.fotogallery &&
                <div className="magnific-popup">
                  <h2 className="servizi__subject">Fotogallery</h2>
                  <PhotoGallery data={data.fotogallery}/>
                </div>
              }    

            </div>
            <div className="col-md-4">
              
              {!!data.attachment &&
                <DocumentiUtili data={data.attachment}/>
              }
              <DoveSiamo/>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

}

export default Emergenza;

export const query = graphql`
query EmergenzaQuery{
  contentfulContenutoGenerico (contentful_id: {eq: "losUgZCFy08E4CSWGg4wW"}) {
  	abstract {
      childMarkdownRemark {
        html
      }
    }
    attachment {
      title
      file {
        url
      } 
    }
    fotogallery {
      ...PhotoGalleryFragment
    }
    staff {
      nome
      cognome
      friendlyUrl
      ruolo
      immagineLista {
        file {
          url
        }
      }
      immagine {
        file {
          url
        }
      }
    }
  }
  hero: file(relativePath: {eq: "hero-studenti.png"}) {
    childImageSharp{
      fluid(maxWidth: 1920){
        ...GatsbyImageSharpFluid_withWebp
        src
      }
    }
  }
}
`