import React from 'react'

class EmergenzeTab extends React.Component {

  render() { 

    return (
      <div className="emergenze">
          <img src="../images/contents/icona_emergenza.png" alt="Plus ico" className="emergenze__ico d-none d-sm-block" />
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="emergenza emergenza--piccolianimali">
                  <span className="emergenza__title">Piccoli animali</span>
                  <span className="emergenza__phone">0250334116</span>
                  <span className="emergenza__address">Via dell'Università 6, Lodi</span>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div title="Cavalli" className="emergenza emergenza--cavalli">
                  <span className="emergenza__title">Cavalli</span>
                  <span className="emergenza__phone">3299388472</span>
                  <span className="emergenza__address">Via dell'Università 6, Lodi</span>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div title="Ruminanti e suini" className="emergenza emergenza--ruminanti">
                  <span className="emergenza__title">Ruminanti e suini</span>
                  <span className="emergenza__phone">3341064108</span>
                  <span className="emergenza__address">Via dell'Università 6, Lodi</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }

}

export default EmergenzeTab;