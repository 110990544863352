import React from 'react'
import Link from 'gatsby-link'


class DoveSiamo extends React.Component {

  render() { 
    return (
      <div className="webcontent webcontent--noborder">
        <h2 className="webcontent__title">Dove siamo</h2>
        <div className="webcontent__abstract">
          <div className="iframe-responsive">
            <iframe title="dove siamo" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2806.100949949157!2d9.46993431540908!3d45.30638635198104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47812c437b80834f%3A0xc18167a437b35c7a!2sVia+dell&#39;Universit%C3%A0%2C+26900+Lodi+LO!5e0!3m2!1sit!2sit!4v1521538395815" width="600" height="450" frameBorder="0" border="0" allowFullScreen>..</iframe>
          </div>
          <div className="text-right">
            <Link to="/dove-siamo/" title="Come raggiungerci">Come raggiungerci</Link>
          </div>
        </div>
      </div>
    )
  }

}

export default DoveSiamo;